import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import ILibrary from "../../../../../../../../Models/API/ILibrary";
import { useOutletContext } from "react-router-dom";
import {
  CommandBar,
  ICommandBarItemProps,
  SearchBox,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Selection,
  SelectionMode,
  IColumn,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  ProgressIndicator,
  DetailsList,
  IconButton,
  IContextualMenuProps,
  Panel,
} from "@fluentui/react";
import IDocument from "../../../../../../../../Models/API/IDocument";
import { DocumentServiceContext } from "../../../../../../../../Services/API/DocumentService";
import moment from "moment";
import axios, {
  AxiosError,
  AxiosProgressEvent,
  CancelTokenSource,
} from "axios";
import { Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import "./Documents.scss";
import { removeDiacritics } from "../../../../../../../../Utilities/Strings";
import JSZip from "jszip";
import saveAs from "file-saver";
import { DialogServiceContext } from "../../../../../../../../Services/Dialogs/DialogService";
import MetadataField from "../../../../../../../../Components/Metadata/MetadataField/MetadataField";
import { trackPageView } from "../../../../../../../../Services/AppInsights";

export const VALID_UPLOAD_FILE_EXTENSIONS = [
  "docx",
  "pptx",
  "xlsx",
  "pdf",
  "txt",
];

const shimmerColumns: IColumn[] = [
  {
    key: "Icon",
    name: "",
    minWidth: 20,
    maxWidth: 20,
    isResizable: false,
  },
  {
    key: "FileName",
    name: "File Name",
    minWidth: 50,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "Title",
    name: "Title",
    minWidth: 100,
    isResizable: true,
  },
  {
    key: "FileOptions",
    name: "",
    minWidth: 20,
    maxWidth: 20,
    isResizable: false,
  },
  {
    key: "OwnerName",
    name: "Uploaded By",
    minWidth: 150,
    maxWidth: 200,
    isResizable: false,
  },
  {
    key: "State",
    name: "State",
    minWidth: 100,
    maxWidth: 100,
    isResizable: false,
  },
  {
    key: "Created",
    name: "Created",
    minWidth: 100,
    maxWidth: 100,
    isResizable: false,
  },
  {
    key: "Updated",
    name: "Updated",
    minWidth: 100,
    maxWidth: 100,
    isResizable: false,
  },
];

const LibraryDocuments: FC = () => {
  const documentsService = useContext(DocumentServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const { library } = useOutletContext<{ library: ILibrary }>();
  const [documents, setDocuments] = useState<IDocument[] | null>(null);
  const [filteredDocuments, setFilteredDocuments] = useState<
    IDocument[] | null | undefined
  >(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<
    ICommandBarItemProps[]
  >([]);
  const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
  const [hideUploadDialog, setHideUploadDialog] = useState<boolean>(true);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);
  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(
    undefined
  );
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [currentDocument, setCurrentDocument] = useState<IDocument>();
  const [savingDocument, setSavingDocument] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadDocuments();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Runs when the filter keyword changes
  useEffect(() => {
    if (documents && filterKeyword !== undefined) {
      setFilteredDocuments(
        filterKeyword
          ? documents?.filter((doc) => {
              return (
                removeDiacritics(doc.FileName.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1 ||
                removeDiacritics(doc.Metadata["title"].toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
              );
            })
          : documents
      );
    }
  }, [filterKeyword, documents]);

  const reloadDocuments = async () => {
    if (isMounted) setDocuments([]);
    if (isMounted) setFilteredDocuments(undefined);
    let docs = await documentsService!.GetAll(library.Id);
    docs = docs!.sort((docA, docB) =>
      docA.FileName.localeCompare(docB.FileName)
    );
    if (isMounted) setFilteredDocuments(docs);
    if (isMounted) setDocuments(docs);
  };

  const downloadDocuments = async () => {
    if (downloading) return;
    setDownloading(true);
    if (selectedDocuments.length > 1) {
      const zip = new JSZip();
      for (let i = 0; i < selectedDocuments.length; i++) {
        const doc = selectedDocuments[i];
        const downloadedDoc = await documentsService!.Download(doc.Id);
        zip.file(
          doc.FileName,
          new Blob([downloadedDoc!], {
            type: "application/octet-stream",
          })
        );
      }
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${library.Title}.zip`);
    } else {
      const downloadedDoc = await documentsService!.Download(
        selectedDocuments[0].Id
      );
      saveAs(
        new Blob([downloadedDoc!], {
          type: "application/octet-stream",
        }),
        selectedDocuments[0].FileName
      );
    }
    setDownloading(false);
  };

  // Runs when the selected documents change
  useEffect(() => {
    let barButtons: ICommandBarItemProps[] = [];

    if (library.PermissionType !== "Read")
      barButtons = [
        ...barButtons,
        {
          key: "upload",
          text: "Upload",
          iconProps: { iconName: "Upload" },
          onClick: (event, item) => {
            inputRef.current?.click();
          },
        },
      ];

    barButtons = [
      ...barButtons,
      {
        key: "download",
        text: "Download",
        iconProps: { iconName: downloading ? "CloudDownload" : "Download" },
        onClick: (event, item) => {
          downloadDocuments();
        },
        disabled: selectedDocuments.length === 0,
      },
    ];

    if (library.PermissionType !== "Read")
      barButtons = [
        ...barButtons,
        {
          key: "delete",
          text: "Delete",
          iconProps: { iconName: "Delete" },
          onClick: (event, item) => {
            setHideDeleteDialog(false);
          },
          disabled: selectedDocuments.length === 0,
        },
      ];

    barButtons = [
      ...barButtons,
      {
        key: "refresh",
        text: "Refresh",
        iconProps: { iconName: "Refresh" },
        onClick: (event, item) => {
          resetState();
          reloadDocuments();
        },
      },
    ];

    setCommandBarButtons(barButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocuments, downloading]);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedDocuments(selection.getSelection() as IDocument[]);
    },
  });

  const menuProps = (document: IDocument): IContextualMenuProps => {
    return {
      items: [
        {
          key: "editMetadata",
          text: "Edit Metadata",
          iconProps: { iconName: "Edit" },
          ariaDescription: "Edit document metadata",
          onClick: (event, item) => {
            setCurrentDocument(document);
          },
        },
      ],
    };
  };

  const onRenderItemColumn = (
    item: IDocument,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === "Icon") {
      return (
        <Icon
          {...getFileTypeIconProps({
            extension:
              item.FileName.split(".")[item.FileName.split(".").length - 1],
            size: 20,
            imageFileType: "svg",
          })}
        />
      );
    } else if (column?.key === "FileOptions" && item.State === "Completed") {
      return (
        <>
          <IconButton
            title="Options"
            style={{ height: 20 }}
            iconProps={{ iconName: "More" }}
            menuProps={menuProps(item)}
            menuIconProps={{ style: { display: "none" } }}
          ></IconButton>
        </>
      );
    } else if (column?.key === "Title") {
      return <span title={item.Metadata.title}>{item.Metadata.title}</span>;
    } else if (column?.key === "State") {
      return (
        <>
          <span>{item.State}</span>
          {item.State === "Error" && (
            <IconButton
              title={item.ErrorMessage}
              style={{ height: 12 }}
              iconProps={{ iconName: "Warning" }}
              menuProps={menuProps(item)}
              menuIconProps={{ style: { display: "none" } }}
            ></IconButton>
          )}
        </>
      );
    } else if (column?.key === "Updated") {
      return moment(item.Metadata.updated).format("DD MMM yyyy");
    } else if (column?.key === "Created") {
      return moment(item.Metadata.created).format("DD MMM yyyy");
    } else {
      return item![column?.key as keyof IDocument];
    }
  };

  const deleteDocuments = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const selectedDocs = [...selectedDocuments];
    resetState();
    for (let i = 0; i < selectedDocs.length; i++) {
      const doc = selectedDocs[i];
      promises.push(documentsService?.Delete(doc.Id));
    }
    await Promise.allSettled(promises);
    reloadDocuments();
  };

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      let newFiles = new Array<File>();
      for (var i = 0; i < files.length; i++) {
        newFiles.push(files[i]);
      }
      if (
        newFiles.filter((file) => {
          const extension =
            file.name.split(".")[file.name.split(".").length - 1];
          return (
            VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension.toLowerCase()) === -1
          );
        }).length > 0
      ) {
        dialogService?.showWarningDialog(
          "Document Upload",
          "File extension not supported."
        );
      } else {
        const CancelToken = axios.CancelToken;
        const cancelTokenSource = CancelToken.source();
        setCancelTokenSource(cancelTokenSource);
        setHideUploadDialog(false);
        setUploadPercentage(0);
        try {
          await documentsService!.Create(
            library.Id,
            files,
            cancelTokenSource.token,
            (progressEvent: AxiosProgressEvent) => {
              setUploadPercentage(progressEvent.loaded / progressEvent.total!);
            }
          );
          resetState();
          reloadDocuments();
        } catch (error) {
          const axiosError = error as AxiosError;
          if (
            axiosError.response?.status === 409 ||
            axiosError.response?.status === 422
          ) {
            dialogService?.showWarningDialog(
              "Document Upload",
              (axiosError.response.data as any).title
            );
          } else if (axiosError.response?.status === 400) {
            const keys = Object.keys((axiosError.response.data as any).errors);
            if (keys.indexOf("") !== -1) {
              const errorMsg = (axiosError.response.data as any).errors[""][0];
              if (
                errorMsg.toLowerCase().indexOf("request body too large") !== -1
              ) {
                dialogService?.showWarningDialog(
                  "Document Upload",
                  "The provided file exceeds the allowable size limit. Please ensure that the file size is below 100 megabytes (MB) and try again."
                );
              } else {
                dialogService?.showWarningDialog("Document Upload", errorMsg);
              }
            } else {
              const errorMsg = (axiosError.response.data as any).errors["Files"][0];
              dialogService?.showWarningDialog("Document Upload", errorMsg);
            }
          }
        }
      }
      event.target.value = "";
      setHideUploadDialog(true);
    }
  };

  const resetState = (): void => {
    setHideDeleteDialog(true);
    setDocuments([]);
    setFilteredDocuments(undefined);
    setSelectedDocuments([]);
    setFilterKeyword(undefined);
    setSavingDocument(false);
    setCurrentDocument(undefined);
  };

  const cancelUpload = async () => {
    cancelTokenSource!.cancel("Operation canceled");
  };

  const saveDocument = async () => {
    if (!savingDocument) {
      setSavingDocument(true);
      await documentsService!.Update(
        currentDocument!.Id,
        currentDocument!.Metadata
      );
      resetState();
      reloadDocuments();
    }
  };

  const onRenderFooterContent = React.useCallback(
    () => (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <PrimaryButton
          style={{ minWidth: 80 }}
          onClick={saveDocument}
          text={savingDocument ? "" : "Save"}
          disabled={
            library.Metadata &&
            library.Metadata.Fields.filter((field) => {
              return (
                field.Required &&
                (currentDocument?.Metadata[field.InternalName!] === null ||
                  currentDocument?.Metadata[field.InternalName!] ===
                    undefined ||
                  currentDocument?.Metadata[field.InternalName!] === "")
              );
            }).length > 0
          }
        >
          {savingDocument && <Spinner size={SpinnerSize.small} />}
        </PrimaryButton>
        <DefaultButton onClick={() => setCurrentDocument(undefined)}>
          Cancel
        </DefaultButton>
      </Stack>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDocument, savingDocument]
  );

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel="Library actions" />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => setFilterKeyword(newValue)}
            placeholder="Filter by file name"
            iconProps={{ iconName: "Filter" }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ minHeight: 0, overflowY: "auto" }}>
          <DetailsList
            setKey="documents"
            items={filteredDocuments || []}
            columns={shimmerColumns}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid="Documents"
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            className="documents-list"
          />
          <input
            ref={inputRef}
            type="file"
            multiple={true}
            accept={VALID_UPLOAD_FILE_EXTENSIONS.map((ext) => `.${ext}`).join(
              ","
            )}
            hidden={true}
            onChange={onFileChange}
          />
          {(filteredDocuments === undefined ||
            filteredDocuments?.length === 0) && (
            <>
              {filteredDocuments?.length === 0 && (
                <Text
                  variant="large"
                  block
                  style={{
                    textAlign: "center",
                    opacity: 0.5,
                  }}
                >
                  No documents found
                </Text>
              )}
              {filteredDocuments === undefined && (
                <Spinner size={SpinnerSize.large} />
              )}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Delete Documents",
          subText: "Are you sure you want to delete the following documents?",
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedDocuments.map((doc) => {
            return (
              <Text style={{wordBreak: "break-word"}} key={doc.Id} block>
                {doc.FileName}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={deleteDocuments} text="Delete" />
          <DefaultButton
            onClick={() => setHideDeleteDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideUploadDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          showCloseButton: false,
          type: DialogType.normal,
          title: "Document Upload",
        }}
      >
        {(uploadPercentage < 1 && (
          <ProgressIndicator
            description="Uploading..."
            percentComplete={uploadPercentage}
          />
        )) || <ProgressIndicator description="Processing..." />}
        {uploadPercentage < 1 && (
          <DialogFooter>
            <PrimaryButton
              disabled={uploadPercentage === 1}
              onClick={cancelUpload}
              text="Cancel"
            />
          </DialogFooter>
        )}
      </Dialog>
      <Panel
        isOpen={currentDocument !== undefined}
        onDismiss={() => setCurrentDocument(undefined)}
        headerText="Update Document"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        {currentDocument && (
          <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
            {library.Metadata &&
              library.Metadata.Fields.map((field, index) => {
                return (
                  <Stack.Item key={`field_${index}`}>
                    <MetadataField
                      field={field}
                      value={currentDocument!.Metadata[field.InternalName!]}
                      setValue={(value) => {
                        setCurrentDocument({
                          ...currentDocument!,
                          Metadata: {
                            ...currentDocument!.Metadata,
                            [field.InternalName!]: value,
                          },
                        });
                      }}
                    ></MetadataField>
                  </Stack.Item>
                );
              })}
          </Stack>
        )}
      </Panel>
    </>
  );
};

export default LibraryDocuments;
