import { FC, useContext, useEffect, useState } from 'react';
import { Dropdown, Spinner, SpinnerSize, Stack, StackItem, Text } from '@fluentui/react';
import AIToolsCarousel from '../../../Components/AIToolsCarousel/AIToolsCarousel';
import { catalogServiceContext } from '../../../Services/API/CatalogService';
import ICatalogsWithAiTools from '../../../Models/API/IAiToolsCategories';
import IAIToolApp from '../../../Models/API/IAIToolApp';
import { trackPageView } from '../../../Services/AppInsights';
import ConfigurationService from '../../../Services/ConfigurationService';

const AIApps: FC = () => {
  const catalogsService = useContext(catalogServiceContext);

  const [categories, setCategories] = useState<ICatalogsWithAiTools[]>([]);
  const [tools, setTools] = useState<IAIToolApp[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const sortTools = (tools: IAIToolApp[]): IAIToolApp[] => {
    return tools.sort((a, b) => {
      // Sort by Published
      if (a.Published !== b.Published) {
        return a.Published ? -1 : 1;
      }

      // Sort by PublishedDate (earliest first) if available, otherwise by CreatedDate
      const dateA = a.PublishedDate
        ? new Date(a.PublishedDate).getTime()
        : new Date(a.Created).getTime();
      const dateB = b.PublishedDate
        ? new Date(b.PublishedDate).getTime()
        : new Date(b.Created).getTime();

      return dateA - dateB;
    });
  };

  const loadCategories = async () => {
    setIsLoading(true);
    const categories = await catalogsService?.GetAllCatalogAiTools();

    if (categories) {
      setCategories(categories.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName)));

      var allTools = categories.map(x => x.AITools).flat();
      setTools(sortTools(allTools));
    }
    setIsLoading(false);
  };

  const onCategoryChange = async (key: string): Promise<void> => {
    if (key === 'all') {
      var allTools = categories.map(x => x.AITools).flat();
      setTools(sortTools(allTools));
    } else {
      const category = categories.find(c => c.Id.toString() === key);

      if (category) {
        setTools(sortTools(category.AITools));
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadCategories();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - AI Service Catalog`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack verticalFill style={{ minHeight: 0, overflowY: 'auto' }}>
      <StackItem style={{ padding: 40 }}>
        <Text block variant='xLargePlus'>
          Explore the catalog
        </Text>
        <Text block style={{ marginTop: 10 }}>
          Discover the best AI tools available in JTI and boost your productivity, enhance
          decision-making, and experience the power of real-time AI capabilities firsthand.
        </Text>
        <div style={{ marginTop: 40 }}>
          <Dropdown
            style={{ width: 200 }}
            placeholder='Choose a category'
            options={[
              { key: 'all', text: 'Show All' },
              ...(categories
                ? categories.map(c => ({
                    key: c.Id.toString(),
                    text: c.DisplayName,
                  }))
                : []),
            ]}
            onChange={(event: any, item: any) => onCategoryChange(item.key)}
          />
        </div>
      </StackItem>
      <StackItem verticalFill grow style={{ padding: 40 }}>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner style={{ display: 'inline-block' }} size={SpinnerSize.large} />
          </div>
        ) : tools && tools.length ? (
          <AIToolsCarousel tools={tools} />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Text variant='mediumPlus'>
              No AI tools are currently available. Please check back later.
            </Text>
          </div>
        )}
      </StackItem>
    </Stack>
  );
};

export default AIApps;
