import { FC, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { PlaygroundStart } from "./Playground/PlaygroundStart";
import { CatalogStart } from "./Catalog/CatalogStart";
import Role from "../../Components/Role/Role";
import { UserServiceContext } from "../../Services/API/UserService";
import { AIAppsStart } from "./AIApps/AIAppsStart";
import { VersionManagerServiceContext } from "../../Services/VersionManagerService";
import Maintenance from "../Maintenance/Maintenance";
import IUserInformation from "../../Models/API/IUserInformation";
import AuthenticationService from "../../Services/AuthenticationService";

export const maxWebsiteWidth = 1440;

interface IAppStartProps {
  loading: boolean;
}

const AppStart: FC<IAppStartProps> = ({ loading }) => {
  const versionManagerService = useContext(VersionManagerServiceContext);
  const userService = useContext(UserServiceContext);
  const [localLoading, setLocalLoading] = useState(true);
  const [maintenance, setMaintenance] = useState<boolean>(false);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const init = async () => {
    if (!loading) {
      const maintenance = await versionManagerService?.inMaintenance();
      setMaintenance(maintenance!);
      if (!maintenance || AuthenticationService.Default.HasRole("admin"))
        await userService?.GetUserInformation();
      setLocalLoading(false);
    }
  };

  return (
    <>
      {(maintenance && !AuthenticationService.Default.HasRole("admin") && (
        <Maintenance />
      )) || (
        <Routes>
          <Route
            path="ai-apps-catalog/*"
            element={
              <Role
                children={<AIAppsStart loading={loading || localLoading} />}
              />
            }
          ></Route>
          <Route
            path="catalogs/:catalogId/*"
            element={
              <Role
                children={<CatalogStart loading={loading || localLoading} />}
              />
            }
          ></Route>
          <Route
            path="*"
            element={
              <Role
                children={<PlaygroundStart loading={loading || localLoading} />}
              />
            }
          />
        </Routes>
      )}
    </>
  );
};

export { AppStart };
