import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { INavLink, INavLinkGroup, INavStyles, Nav, Stack, StackItem } from '@fluentui/react';

const navStyles: Partial<INavStyles> = {
  root: {
    width: 250,
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  groupContent: {
    marginBottom: 0,
  },
  chevronButton: {
    fontSize: '1rem',
  },
  navItem: {
    padding: '0px 0 0px 10px',
    lineHeight: '20px',
    minHeight: '20px',
  },
  linkText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    lineHeight: '20px',
  },
};

const ApiReference: FC = () => {
  const [navLinkGroups, setNavLinkGroups] = useState<INavLinkGroup[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchNavLinks = async () => {
      try {
        const response = await fetch('/docs/structure.json');
        const data = await response.json();
        const currentPath = location.pathname.replace('/api-reference/', '');

        const links = buildLinks(data, currentPath);
        setNavLinkGroups([{ links }]);
      } catch (err) {
        console.error('Error fetching navigation structure:', err);
      }
    };

    fetchNavLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const buildLinks = (groups: any[], currentPath: string, parentFolder = ''): INavLink[] => {
    return groups.map((group: any) => {
      const groupFolder = `${parentFolder}${group.folder ? `${group.folder}/` : ''}`;
      const isGroupExpanded = currentPath.startsWith(groupFolder);

      return {
        name: group.name,
        key: `g-${groupFolder}`,
        url: '',
        isExpanded: isGroupExpanded,
        links: [
          // Build subgroups recursively
          ...(group.subGroups ? buildLinks(group.subGroups, currentPath, groupFolder) : []),

          // Add files
          ...(group.files || []).map((file: any) => {
            const filePath = `${groupFolder}${file.file.replace('.md', '')}`;
            return {
              name: file.name,
              key: filePath,
              url: '',
              isSelected: filePath === currentPath,
              onClick: () =>
                navigate({
                  pathname: filePath,
                }),
            };
          }),
        ],
      };
    });
  };

  return (
    <Stack
      verticalFill
      horizontal
      tokens={{ childrenGap: 20 }}
      style={{ minHeight: 0, overflowY: 'auto', padding: '0 20px' }}
    >
      <StackItem
        verticalFill
        shrink
        style={{
          overflowY: 'auto',
          overflowX: 'auto',
          minWidth: 250,
        }}
      >
        <Nav
          selectedKey={location.pathname.replace('/api-reference/', '') || 'none'}
          styles={navStyles}
          groups={navLinkGroups}
        />
      </StackItem>
      <StackItem verticalFill grow shrink style={{ overflowY: 'auto' }}>
        <Outlet />
      </StackItem>
    </Stack>
  );
};

export default ApiReference;
