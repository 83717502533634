import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { trackPageView } from '../../../../../../Services/AppInsights';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import { Stack, StackItem } from '@fluentui/react';
import AuthenticationService from '../../../../../../Services/AuthenticationService';
import styles from './AiToolTest.module.scss';
import { AppCatalogServiceContext } from '../../../../../../Services/AppCatalogService';
import IAIToolApp from '../../../../../../Models/API/IAIToolApp';

export interface IAutomateTestProps {}

const AiToolTest: FC<IAutomateTestProps> = () => {
  const { aiToolApp } = useOutletContext<{
    aiToolApp: IAIToolApp;
  }>();
  const iFrame = useRef<HTMLIFrameElement>(null);
  const appCatalogService = useContext(AppCatalogServiceContext);

  const [originalTheme, setOriginalTheme] = useState<string>();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${aiToolApp.DisplayName}`;
    setOriginalTheme(appCatalogService?.darkMode ? 'darkMode' : 'lightMode');
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (iFrame.current) {
      iFrame.current.contentWindow?.postMessage(
        {
          eventType: appCatalogService?.darkMode ? 'darkMode' : 'lightMode',
        },
        '*'
      );
    }
  }, [appCatalogService?.darkMode]);

  return (
    <Stack verticalFill style={{ minHeight: 0, overflowY: 'auto' }}>
      <StackItem verticalFill grow style={{ padding: 10 }}>
        {aiToolApp.AppUrl ? (
          <iframe
            title='AI Tool'
            sandbox='allow-scripts allow-top-navigation allow-same-origin allow-top-navigation-by-user-activation allow-modals allow-downloads'
            className={styles.iframe}
            ref={iFrame}
            src={`${aiToolApp.AppUrl}?accountName=${AuthenticationService.Default.Account?.username}&theme=${originalTheme}`}
          ></iframe>
        ) : (
          <p className={styles.noUrlMessage}>
            The AI Tool requires a valid URL to function. Please navigate to the{' '}
            <strong>Settings</strong> page and provide a valid URL to test the tool.
          </p>
        )}
      </StackItem>
    </Stack>
  );
};

export default AiToolTest;
