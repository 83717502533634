import React from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import DocsMarkdown from '../DocsMarkdown/DocsMarkdown';

const MarkdownTabs = ({ tabs }: { tabs: { title: string; content: string }[] }) => (
  <Pivot>
    {tabs.map((tab, index) => (
      <PivotItem headerText={tab.title} key={index}>
        <DocsMarkdown content={tab.content} />
      </PivotItem>
    ))}
  </Pivot>
);

export default MarkdownTabs;
