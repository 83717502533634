import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  CommandBar,
  ICommandBarItemProps,
  SearchBox,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Selection,
  SelectionMode,
  IColumn,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DetailsList,
  Link,
  Icon,
} from '@fluentui/react';
import './CatalogApplications.scss';
import { removeDiacritics } from '../../../../Utilities/Strings';
import { catalogServiceContext } from '../../../../Services/API/CatalogService';

import IAppCatalog from '../../../../Models/API/IAppCatalog';
import moment from 'moment';
import { chatAppServiceContext } from '../../../../Services/API/ChatAppService';
import { AppType, appTypeMap } from '../../../../Models/API/AppType';
import { agentAppServiceContext } from '../../../../Services/API/AgentAppService';
import IBaseApp from '../../../../Models/API/IBaseApp';
import { automateAppServiceContext } from '../../../../Services/API/AutomateAppService';
import { trackPageView } from '../../../../Services/AppInsights';
import ConfigurationService from '../../../../Services/ConfigurationService';
import { aiToolAppServiceContext } from '../../../../Services/API/AiToolAppService';

const shimmerColumns: IColumn[] = [
  {
    key: 'Icon',
    name: '',
    minWidth: 20,
    maxWidth: 20,
    isResizable: true,
  },
  {
    key: 'DisplayName',
    name: 'Display Name',
    minWidth: 200,
    isResizable: true,
  },
  {
    key: 'Type',
    name: 'Type',
    minWidth: 100,
    isResizable: true,
  },
  {
    key: 'OwnerName',
    name: 'Owner',
    minWidth: 200,
    isResizable: true,
  },
  {
    key: 'Updated',
    name: 'Last Update',
    minWidth: 200,
    isResizable: true,
  },
];

const CatalogApplications: FC = () => {
  const navigate = useNavigate();
  const catalogService = useContext(catalogServiceContext);
  const chatAppService = useContext(chatAppServiceContext);
  const agentAppService = useContext(agentAppServiceContext);
  const automateAppService = useContext(automateAppServiceContext);
  const aiToolAppService = useContext(aiToolAppServiceContext);

  const { catalog } = useOutletContext<{ catalog: IAppCatalog }>();
  const [apps, setApps] = useState<IBaseApp[] | null>(null);
  const [filteredApps, setFilteredApps] = useState<IBaseApp[] | null | undefined>(undefined);
  const [selectedApps, setSelectedApps] = useState<IBaseApp[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<ICommandBarItemProps[]>([]);
  const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);

  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(undefined);

  const [isMounted, setIsMounted] = useState<boolean>(true);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${catalog.DisplayName} Apps`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadApps();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Runs when the filter keyword changes
  useEffect(() => {
    if (apps && filterKeyword !== undefined) {
      setFilteredApps(
        filterKeyword
          ? apps?.filter(chatApp => {
              return (
                removeDiacritics(chatApp.DisplayName.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
              );
            })
          : apps
      );
    }
  }, [filterKeyword, apps]);

  const reloadApps = async () => {
    if (isMounted) setApps([]);
    if (isMounted) setFilteredApps(undefined);
    let chatApps = await catalogService!.GetAllCatalogChats(catalog.Id);

    chatApps = chatApps!.sort((appA, appB) => appA.DisplayName.localeCompare(appB.DisplayName));
    if (isMounted) setFilteredApps(chatApps);
    if (isMounted) setApps(chatApps);
  };

  // Runs when the selected chat apps change
  useEffect(() => {
    let barButtons: ICommandBarItemProps[] = [];

    if (catalog.PermissionType && catalog.PermissionType !== 'Read')
      barButtons = [
        ...barButtons,
        {
          key: 'new',
          text: 'New Application',
          iconProps: { iconName: 'Add' },
          subMenuProps: {
            items: [
              {
                key: 'chat/new',
                text: 'Chat',
                iconProps: { iconName: 'Chat' },
                onClick: (event, item) => {
                  navigate(item?.key!, {
                    relative: 'route',
                  });
                },
              },
              {
                key: 'agent/new',
                text: 'Agent',
                iconProps: { iconName: 'ChatBot' },
                onClick: (event, item) => {
                  navigate(item?.key!, {
                    relative: 'route',
                  });
                },
              },
              {
                key: 'automate/new',
                text: 'Automate',
                iconProps: { iconName: 'PowerApps' },
                onClick: (event, item) => {
                  navigate(item?.key!, {
                    relative: 'route',
                  });
                },
              },
              {
                key: 'aitool/new',
                text: 'AI Tool',
                iconProps: { iconName: 'ToolBox' },
                onClick: (event, item) => {
                  navigate(item?.key!, {
                    relative: 'route',
                  });
                },
              },
            ],
          },
        },
      ];

    barButtons = [
      ...barButtons,
      {
        key: 'delete',
        text: 'Delete',
        iconProps: { iconName: 'Delete' },
        onClick: (event, item) => {
          setHideDeleteDialog(false);
        },
        disabled: selectedApps.length === 0,
      },
    ];

    barButtons = [
      ...barButtons,
      {
        key: 'refresh',
        text: 'Refresh',
        iconProps: { iconName: 'Refresh' },
        onClick: (event, item) => {
          resetState();
          reloadApps();
        },
      },
    ];

    setCommandBarButtons(barButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApps]);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedApps(selection.getSelection() as IBaseApp[]);
    },
    canSelectItem(item, index) {
      const app = item as IBaseApp;
      if (app.OverrideCatalogPermissions) return app.PermissionType === 'Owner';
      return (
        catalog.PermissionType === 'Write' ||
        catalog.PermissionType === 'FullControl' ||
        catalog.PermissionType === 'Owner'
      );
    },
  });

  const onRenderItemColumn = (item: IBaseApp, index?: number, column?: IColumn): any => {
    if (column?.key === 'Updated') {
      return moment(item.Updated).format('DD MMM yyyy');
    } else if (column?.key === 'DisplayName') {
      return <Link onClick={() => navigateSomewhere(item)}>{item.DisplayName}</Link>;
    } else if (column?.key === 'Type') {
      return appTypeMap.get(item.Type) ? appTypeMap.get(item.Type) : 'Unknown';
    } else if (column?.key === 'Icon') {
      return (
        <Icon
          style={{ fontSize: '1rem' }}
          iconName={
            item.Type === AppType.ChatApp
              ? 'Chat'
              : item.Type === AppType.AgentApp
              ? 'ChatBot'
              : item.Type === AppType.AutomateApp
              ? 'PowerApps'
              : item.Type === AppType.AiTool
              ? 'ToolBox'
              : 'MicrosoftFlowLogo'
          }
        />
      );
    } else {
      return item![column?.key as keyof IBaseApp];
    }
  };

  const navigateSomewhere = (app: IBaseApp) => {
    let route = `${app.Id.toString()}`;
    switch (app.Type) {
      case AppType.AgentApp:
        route = 'agent/' + route;
        break;
      case AppType.ChatApp:
        route = 'chat/' + route;
        break;
      case AppType.AutomateApp:
        route = 'automate/' + route;
        break;
      case AppType.AiTool:
        route = 'aitool/' + route;
        break;
      default:
        route = 'catalogs';
        break;
    }

    navigate(route, {
      relative: 'route',
    });
  };

  const deleteApps = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const appsToDelete = [...selectedApps];
    resetState();
    for (let i = 0; i < appsToDelete.length; i++) {
      const app = appsToDelete[i];
      switch (app.Type) {
        case AppType.ChatApp:
          promises.push(chatAppService?.Delete(app.Id));
          break;
        case AppType.AgentApp:
          promises.push(agentAppService?.Delete(app.Id));
          break;
        case AppType.AutomateApp:
          promises.push(automateAppService?.Delete(app.Id));
          break;
        case AppType.AiTool:
          promises.push(aiToolAppService?.Delete(app.Id));
          break;
      }
    }
    await Promise.allSettled(promises);
    reloadApps();
  };

  const resetState = (): void => {
    setHideDeleteDialog(true);
    setApps([]);
    setFilteredApps(undefined);
    setSelectedApps([]);
    setFilterKeyword(undefined);
  };

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel='Application actions' />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => setFilterKeyword(newValue)}
            placeholder='Filter by application name'
            iconProps={{ iconName: 'Filter' }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ overflowY: 'auto' }}>
          <DetailsList
            setKey='apps'
            items={filteredApps || []}
            columns={shimmerColumns}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid='Chat Apps'
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            className='chat-apps-list'
          />
          {(filteredApps === undefined || filteredApps?.length === 0) && (
            <>
              {filteredApps?.length === 0 && (
                <Text
                  variant='large'
                  block
                  style={{
                    textAlign: 'center',
                    opacity: 0.5,
                  }}
                >
                  No Apps found
                </Text>
              )}
              {filteredApps === undefined && <Spinner size={SpinnerSize.large} />}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Delete applications',
          subText: 'Are you sure you want to delete the following applications?',
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedApps.map(chatApp => {
            return (
              <Text key={chatApp.Id} block>
                {chatApp.DisplayName}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={deleteApps} text='Delete' />
          <DefaultButton onClick={() => setHideDeleteDialog(true)} text='Cancel' />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default CatalogApplications;
