import "./Navigation.scss";
import { Link } from "react-router-dom";
import { Link as FluentLink, Stack, Text } from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import AuthenticationService from "../../../Services/AuthenticationService";
import { UserServiceContext } from "../../../Services/API/UserService";
import IUserInformation from "../../../Models/API/IUserInformation";
import DarkModeSwitch from "../../DarkModeSwitch/DarkModeSwitch";
import { AppCatalogServiceContext } from "../../../Services/AppCatalogService";

const Navigation: FC = () => {
  const appCatalogService = useContext(AppCatalogServiceContext);
  const userService = useContext(UserServiceContext);
  const [user, setUser] = useState<IUserInformation>();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    const user = await userService?.GetUserInformation();
    setUser(user!);
  };

  return (
    <Stack
      horizontal
      className="header"
      tokens={{ padding: 16, childrenGap: 20 }}
      grow
      verticalAlign="center"
    >
      <Link to="/" style={{ fontSize: 1, display: "inline-block" }}>
        <img src={appCatalogService?.logo} alt="" />
      </Link>
      <Stack horizontal className="nav" grow>
        <Text variant="xLarge">AI Service Catalog</Text>
      </Stack>
      <Stack className="hideInMobile">
        <Stack.Item>
          <DarkModeSwitch />
        </Stack.Item>
      </Stack>
      <Stack style={{ textAlign: "right" }} className="hideInMobile">
        <Stack.Item>
          <Text block variant="medium">
            <FluentLink
              onClick={(evt) => AuthenticationService.Default.Logout()}
            >
              Logout
            </FluentLink>
          </Text>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export { Navigation };
