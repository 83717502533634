import React, { FC, useState } from "react";
import ITraceMessage, { IChatMessage } from "../../Models/API/ITraceMessage";
import { Checkbox, Stack, StackItem, Text, TextField } from "@fluentui/react";
import TextareaAutosize from "react-textarea-autosize";
import "./TraceMessage.scss";
import moment from "moment";
import classNames from "classnames";

export interface ITraceMessageProps {
  traceMessage: ITraceMessage;
}

const TraceMessage: FC<ITraceMessageProps> = ({ traceMessage }) => {
  const [systemTools, setSystemTools] = useState<string[]>([]);

  const getContent = (message: IChatMessage) => {
    switch (message.role) {
      case "User":
        return message.content;
      case "Assistant":
      case "Tool":
        return JSON.stringify(JSON.parse(message.content!), undefined, 10);
      default:
        return "";
    }
  };

  const getSystemContent = (message: IChatMessage) => {
    let obj = JSON.parse(message.content!);
    if (systemTools.indexOf(message.id) === -1) {
      let obj = JSON.parse(message.content!);
      return obj.message;
    }
    delete obj["message"];
    obj.tools = obj.tools?.map((tool: any) => {
      return {
        ...tool,
        arguments: JSON.parse(tool.arguments),
      };
    });
    return JSON.stringify(obj, undefined, 20);
  };

  const getName = (message: IChatMessage) => {
    switch (message.role) {
      case "User":
        return "User";
      case "Assistant":
        return "Assistant";
      case "Tool":
        return `Tool (${message.tool_call_id})`;
      case "System":
        return "System";
      default:
        return "";
    }
  };

  return (
    <Stack className="traceMessage" tokens={{ childrenGap: 20 }}>
      {traceMessage.chat_history.map((message) => {
        return (
          <React.Fragment key={message.id}>
            {(message.tool_calls.length == 0 && (
              <Stack key={message.id} tokens={{ childrenGap: 10 }}>
                {(message.role == "System" && (
                  <>
                    <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                      <StackItem>
                        <Text variant="small">
                          <strong>System</strong>
                        </Text>
                      </StackItem>
                      <StackItem align="center" grow>
                        <Stack horizontal>
                          <Checkbox
                            onChange={(evt, checked) => {
                              if (checked)
                                setSystemTools([...systemTools, message.id]);
                              else
                                setSystemTools(
                                  systemTools.filter((id) => id !== message.id)
                                );
                            }}
                            checked={systemTools.indexOf(message.id) !== -1}
                          />{" "}
                          <Text variant="small">Show settings</Text>
                        </Stack>
                      </StackItem>
                      <StackItem>
                        <Text variant="small">
                          <strong>
                            {moment(message.created).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </strong>
                        </Text>
                      </StackItem>
                    </Stack>
                    <StackItem>
                      <div
                        className={classNames(
                          "textarea",
                          message.is_active && "active"
                        )}
                      >
                        <TextareaAutosize
                          minRows={1}
                          maxRows={10}
                          value={getSystemContent(message)}
                          readOnly
                        ></TextareaAutosize>
                      </div>
                    </StackItem>
                  </>
                )) || (
                  <>
                    <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                      <StackItem grow>
                        <Text variant="small">
                          {message.role == "Tool" ? (
                            <>
                              <strong>Tool</strong> ({message.tool_call_id})
                            </>
                          ) : (
                            <strong>{getName(message)}</strong>
                          )}
                        </Text>
                      </StackItem>
                      <StackItem>
                        <Text variant="small">
                          <strong>
                            {moment(message.created).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </strong>
                        </Text>
                      </StackItem>
                    </Stack>
                    <StackItem>
                      <div
                        className={classNames(
                          "textarea",
                          message.is_active && "active"
                        )}
                      >
                        <TextareaAutosize
                          minRows={1}
                          maxRows={10}
                          value={getContent(message)}
                          readOnly
                        ></TextareaAutosize>
                      </div>
                    </StackItem>
                  </>
                )}
              </Stack>
            )) ||
              message.tool_calls.map((toolCall) => {
                return (
                  <Stack key={toolCall.id} tokens={{ childrenGap: 10 }}>
                    <Stack
                      className="info"
                      grow
                      horizontal
                      tokens={{ childrenGap: 10 }}
                    >
                      <StackItem grow>
                        <Text variant="small">
                          <strong>Assistant - {toolCall.function_name}</strong>{" "}
                          ({toolCall.id})
                        </Text>
                      </StackItem>
                      <StackItem>
                        <Text variant="small">
                          <strong>
                            {moment(message.created).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </strong>
                        </Text>
                      </StackItem>
                    </Stack>
                    <StackItem>
                      <div
                        className={classNames(
                          "textarea",
                          message.is_active && "active"
                        )}
                      >
                        <TextareaAutosize
                          minRows={1}
                          maxRows={10}
                          value={JSON.stringify(
                            JSON.parse(toolCall.function_arguments),
                            undefined,
                            10
                          )}
                          readOnly
                        ></TextareaAutosize>
                      </div>
                    </StackItem>
                  </Stack>
                );
              })}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default TraceMessage;
