import "./PlaygroundStart.scss";
import Router from "../../../Router";
import { Layer, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { Navigation } from "../../../Components/Navigation/Navigation";
import { Footer } from "../../../Components/Footer/Footer";
import Toast from "../../../Components/Toast/Toast";
import { AppCatalogServiceContext } from "../../../Services/AppCatalogService";
import { FC, useContext, useEffect } from "react";
import { maxWebsiteWidth } from "../AppStart";

export interface IAppStartProps {
  loading: boolean;
}

export const PlaygroundStart: FC<IAppStartProps> = ({ loading }: any) => {
  const appCatalogService = useContext(AppCatalogServiceContext);

  useEffect(() => {
    const execute = async () => {
      await appCatalogService?.SetDefaultTheme();
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(!loading && (
        <>
          <Layer>
            <Stack horizontalAlign="end">
              <Stack.Item>
                <Toast />
              </Stack.Item>
            </Stack>
          </Layer>
          <Stack
            horizontalAlign="center"
            grow
            verticalFill
            style={{ height: "100%", minHeight: 0 }}
          >
            <Stack style={{ maxWidth: maxWebsiteWidth, width: "100%" }}>
              <Navigation />
            </Stack>
            <Stack
              grow
              verticalFill
              style={{
                minHeight: 0,
                maxWidth: maxWebsiteWidth,
                width: "100%",
              }}
              tokens={{ padding: "0 2rem" }}
            >
              <Router></Router>
            </Stack>
            <Stack style={{ maxWidth: maxWebsiteWidth, width: "100%" }}>
              <Footer />
            </Stack>
          </Stack>
        </>
      )) || (
        <Stack style={{ height: "100%" }} verticalAlign="center">
          <Spinner size={SpinnerSize.large} />
        </Stack>
      )}
    </>
  );
};
