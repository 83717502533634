import { FC, createContext, useContext } from 'react';
import { ApiHttpServiceContext } from './ApiHttpService';
import { CacheServiceContext } from './CacheService';
import IUserPermission, { PermissionType } from '../../Models/API/IUserPermission';
import IAiToolAppPermission from '../../Models/API/IAiToolAppPermission.1';
import IAIToolApp from '../../Models/API/IAIToolApp';

export interface INewAiToolApp {
  DisplayName?: string;
  Description?: string;
  AppUrl?: string;
  AppCatalogId?: number;
}

export interface IAiToolAppService {
  // CRUD
  GetAll(): Promise<IAIToolApp[] | null>;
  Get(aiToolAppId: number): Promise<IAIToolApp | null>;
  Create(aiToolApp: INewAiToolApp): Promise<IAIToolApp | null>;
  Update(aiToolAppId: number, agentApp: IAIToolApp): Promise<IAIToolApp | null>;
  Delete(aiToolAppId: number): Promise<void | null>;
  GetLogo(appCatalogId: number): Promise<string | null>;
  UpdateLogo(appCatalogId: number, file: File): Promise<void>;
  // Permissions
  OverrideCatalogPermissions(
    aiToolAppId: number,
    overrideCatalogPermissions: boolean
  ): Promise<void>;
  CreatePermission(
    aiToolAppId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IAiToolAppPermission | null>;
  GetPermissions(aiToolAppId: number): Promise<IAiToolAppPermission[] | null>;
  UpdatePermission(
    catalogId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IAiToolAppPermission | null>;
  CheckUserPermissions(principalId: string, aiToolAppId: number): Promise<IUserPermission[] | null>;
  DeletePermission(catalogId: number, principalId: string): Promise<void | null>;
}

export interface AutomateRunReturn {
  [name: string]: any[];
}

export const aiToolAppServiceContext = createContext<IAiToolAppService | undefined>(undefined);

const AiToolAppService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const controller: string = 'aitools';

  const aiToolAppService: IAiToolAppService = {
    async GetAll(): Promise<IAIToolApp[] | null> {
      return await apiHttpService!.Get<IAIToolApp[]>(`${controller}`);
    },
    async Get(aiToolAppId: number) {
      return await apiHttpService!.Get<IAIToolApp>(`${controller}/${aiToolAppId}`);
    },
    async Delete(aiToolAppId: number) {
      return await apiHttpService!.Delete<void>(`${controller}/${aiToolAppId}`);
    },
    async Create(aiToolApp: INewAiToolApp) {
      return await apiHttpService!.Post<IAIToolApp>(`${controller}`, aiToolApp);
    },
    async Update(aiToolAppId: number, aiToolApp: IAIToolApp) {
      cacheService!.ClearCache(`${controller}/${aiToolApp}`);
      return await apiHttpService!.Put<IAIToolApp>(`${controller}/${aiToolAppId}`, aiToolApp);
    },
    async GetLogo(aiToolAppId: number) {
      return await apiHttpService!.Get<string>(`${controller}/${aiToolAppId}/logo`, {}, true, true);
    },
    async UpdateLogo(aiToolAppId: number, file: File) {
      var formData = new FormData();
      formData.append('Image', file);
      await apiHttpService!.Put<void>(`${controller}/${aiToolAppId}/logo`, formData);
    },
    async OverrideCatalogPermissions(aiToolAppId: number, overrideCatalogPermissions: boolean) {
      await apiHttpService!.Post<void>(
        `${controller}/${aiToolAppId}/overrideCatalogPermissions?overrideCatalogPermissions=${overrideCatalogPermissions}`
      );
    },
    async CreatePermission(aiToolAppId: number, principalId: string, type: PermissionType) {
      return await apiHttpService!.Post<IAiToolAppPermission>(
        `${controller}/${aiToolAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async GetPermissions(aiToolAppId: number) {
      return await apiHttpService!.Get<IAiToolAppPermission[]>(
        `${controller}/${aiToolAppId}/permissions`
      );
    },
    async DeletePermission(aiToolAppId: number, principalId: string) {
      await apiHttpService!.Delete<IAiToolAppPermission>(
        `${controller}/${aiToolAppId}/permissions/${principalId}`
      );
    },
    async CheckUserPermissions(principalId: string, aiToolAppId: number) {
      return await apiHttpService!.Get<IUserPermission[]>(
        `${controller}/${aiToolAppId}/permissions/${principalId}`
      );
    },
    async UpdatePermission(aiToolAppId: number, principalId: string, type: PermissionType) {
      return await apiHttpService!.Put<IAiToolAppPermission>(
        `${controller}/${aiToolAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
  };

  return (
    <aiToolAppServiceContext.Provider value={aiToolAppService}>
      {children}
    </aiToolAppServiceContext.Provider>
  );
};

export default AiToolAppService;
