import { Link, Stack } from "@fluentui/react";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfigurationService from "../../Services/ConfigurationService";
import "./Footer.scss";

interface FooterProps {
  maintenance?: boolean;
}

const Footer: FC<FooterProps> = ({ maintenance = false }) => {
  const navigation = useNavigate();
  const location = useLocation();

  const contactSupport = (): void => {
    const mail =
      ConfigurationService.Default.Configuration.Contact.ContactSupportEmail;
    const subject =
      ConfigurationService.Default.Configuration.Contact.ContactSupportSubject;
    window.open(`mailto:${mail}?subject=${subject}`);
  };

  return (
    <Stack horizontal tokens={{ padding: 16 }}>
      <Stack.Item grow>
        <Link
          target="_blank"
          onClick={contactSupport}
          className="ms-fontColor-themePrimary"
        >
          Digital & IT
        </Link>
      </Stack.Item>
      {!maintenance && (
        <Stack.Item align="end">
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Link
              style={{ textDecoration: "none !important" }}
              onClick={() => navigation("/api-reference", { replace: true })}
              className={`ms-fontColor-themePrimary ${
                location.pathname.startsWith("/api-reference") ? "active" : ""
              }`}
            >
              API Reference
            </Link>
            <Link
              style={{ textDecoration: "none !important" }}
              onClick={() => navigation("/versions", { replace: true })}
              className={`ms-fontColor-themePrimary ${
                location.pathname === "/versions" ? "active" : ""
              }`}
            >
              Latest Release:{" "}
              {ConfigurationService.Default.Configuration.AppVersionDate} v
              {ConfigurationService.Default.Configuration.AppVersion}
            </Link>
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

export { Footer };
