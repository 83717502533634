import { FC } from 'react';

import { Link, Stack, StackItem, Text } from '@fluentui/react';

const Default: FC = () => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <StackItem>
        <Text variant='superLarge'>API Reference</Text>
      </StackItem>
      <StackItem>
        <Text variant='xxLarge'>ChatJTI API</Text>
      </StackItem>
      <StackItem>
        <Text variant='large'>Version 0.1</Text>
      </StackItem>
      <StackItem>
        <Link href={`mailto:bogdan.ciungu@jti.com`}>JTI IT Innovation Team</Link>
      </StackItem>
    </Stack>
  );
};

export default Default;
