import { Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import axios from "axios";
import { FC, createContext, useEffect, useState } from "react";

export interface IVersionManagerService {
  inMaintenance(): Promise<boolean>;
}

interface ManifestConfigIcon {
  src: string;
  sizes: string;
  type: string;
}

interface ManifestConfig {
  short_name: string;
  name: string;
  icons: ManifestConfigIcon[];
  start_url: string;
  display: string;
  theme_color: string;
  background_color: string;
  version: string;
  maintenance: string;
}

export const VersionManagerServiceContext = createContext<
  IVersionManagerService | undefined
>(undefined);

const VersionManagerService: FC = ({ children }: any) => {
  const [version, setVersion] = useState<string>();
  const [timeout, setTimeout] = useState<NodeJS.Timeout>();
  const [newVersion, setNewVersion] = useState<string | null>(null);

  useEffect(() => {
    const execute = async () => {
      var manifest = await axios.get<ManifestConfig>("/manifest.json");
      setVersion(manifest.data.version);
    };
    execute();
    return () => {
      clearInterval(timeout);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (version) {
      setTimeout(
        setInterval(() => {
          axios.get<any>("/manifest.json").then((manifest) => {
            if (manifest.data.version !== version) {
              setNewVersion(manifest.data.version);
            }
          });
        }, 300000)
      ); // Every 5 minutes check if new version is available
    }
  }, [version]);

  const versionManagerService: IVersionManagerService = {
    async inMaintenance() {
      var manifest = await axios.get<ManifestConfig>("/manifest.json");
      return manifest.data.maintenance.toLowerCase() === "true";
    },
  };

  return (
    <VersionManagerServiceContext.Provider value={versionManagerService}>
      <Dialog
        hidden={newVersion == null}
        dialogContentProps={{
          title: "New version available",
          subText: `A new version of the playground is available (${newVersion}).`,
          showCloseButton: false,
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => window.location.reload()}
            text="Update"
          />
        </DialogFooter>
      </Dialog>
      {children}
    </VersionManagerServiceContext.Provider>
  );
};

export default VersionManagerService;
