import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import styles from './DocsMarkdown.module.scss';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import MarkdownTabs from '../MarkdownTabs/MarkdownTabs';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { AppCatalogServiceContext } from '../../../Services/AppCatalogService';

const remarkRehype = require('remark-rehype');

const CustomLinkRenderer = ({ href, children }: { href?: string; children: React.ReactNode }) => {
  if (!href) {
    return <span>{children}</span>;
  }

  if (href.startsWith('#')) {
    return (
      <HashLink
        to={href}
        smooth
        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
      >
        {children}
      </HashLink>
    );
  }

  return (
    <a href={href} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  );
};

export interface IDocsMarkdownProps {
  content: string;
  tabs?: any[];
}

const DocsMarkdown: FC<IDocsMarkdownProps> = ({ content, tabs }) => {
  const appCatalogService = useContext(AppCatalogServiceContext);
  const darkMode = appCatalogService!.darkMode ? 'dark' : 'light';

  const [prismTheme, setPrismTheme] = useState(dracula);
  const lastHeadingRef = useRef<string | null>(null);

  const HeadingWithTracking = (Tag: keyof JSX.IntrinsicElements) => {
    return ({ id, children }: { id?: string; children: React.ReactNode }) => {
      const headingText = React.Children.toArray(children).join('');
      lastHeadingRef.current = headingText;
      return <Tag id={id || undefined}>{children}</Tag>;
    };
  };

  const extractTextContent = (children: ReactNode): string => {
    if (typeof children === 'string') {
      return children;
    }

    if (Array.isArray(children)) {
      return children.map(extractTextContent).join('');
    }

    if (React.isValidElement(children)) {
      return extractTextContent(children.props.children);
    }

    return '';
  };

  const mapLanguageToSupported = (language: string): string => {
    const lowercasedLanguage = language.toLowerCase();

    if (['c#', 'cs', 'csharp', 'dotnet', '.net'].includes(lowercasedLanguage)) {
      return 'csharp';
    }

    if (['curl', 'bash'].includes(lowercasedLanguage)) {
      return 'bash';
    }

    if (['rest', 'http'].includes(lowercasedLanguage)) {
      return 'http';
    }

    if (['py', 'python'].includes(lowercasedLanguage)) {
      return 'python';
    }

    if (['json'].includes(lowercasedLanguage)) {
      return 'json';
    }

    return lowercasedLanguage;
  };

  useEffect(() => {
    const prismTheme = darkMode === 'light' ? materialLight : dracula;
    setPrismTheme(prismTheme);
  }, [darkMode]);

  return (
    <ReactMarkdown
      children={content}
      remarkPlugins={[remarkGfm, remarkRehype]}
      rehypePlugins={[rehypeSlug]}
      components={{
        a: CustomLinkRenderer,
        h1: HeadingWithTracking('h1'),
        h2: HeadingWithTracking('h2'),
        h3: HeadingWithTracking('h3'),
        p({ node, children, ...props }) {
          if (children && typeof children[0] === 'string') {
            const content = children[0] as string;

            if (content.startsWith(':::TabsPlaceholder')) {
              const id = content.replace(':::TabsPlaceholder', '').replace(':::', '').trim();
              const tabData = tabs!.find(t => t.id === id);

              if (tabData) {
                return <MarkdownTabs tabs={tabData.content} />;
              }
            }
          }

          return <p {...props}>{children}</p>;
        },
        pre({ node, children, ...props }) {
          let language = '';

          if (
            children &&
            children[0] &&
            typeof children[0] === 'object' &&
            'props' in children[0]
          ) {
            const className = children[0].props.className || '';
            const match = className.match(/language-([\w#+-]+)/);
            if (match) {
              language = match[1];
            }
          }

          const handleCopy = () => {
            const codeText = extractTextContent(children);

            if (codeText) {
              navigator.clipboard.writeText(codeText);
            }
          };

          const codeContent = extractTextContent(children);

          const languageForPrism = mapLanguageToSupported(language);

          return (
            <div className={`${styles.codeContainer}`}>
              <div className={styles.languageContainer}>
                <div className={styles.language}>{language}</div>
                <div className={styles.copyButton} onClick={handleCopy}>
                  Copy
                </div>
              </div>
              <SyntaxHighlighter
                className={styles.withLang}
                language={languageForPrism.toLowerCase()}
                wrapLines
                style={prismTheme}
              >
                {codeContent.trim()}
              </SyntaxHighlighter>
            </div>
          );
        },
        table({ node, children, ...props }) {
          let columnCount = 0;
          if (Array.isArray(children) && React.isValidElement(children[0])) {
            const firstChild = children[0];
            if (firstChild.props && firstChild.props.children) {
              const firstRowChildren = React.Children.toArray(firstChild.props.children);
              if (firstRowChildren.length > 0 && React.isValidElement(firstRowChildren[0])) {
                const firstRow = firstRowChildren[0];
                if (firstRow.props && firstRow.props.children) {
                  columnCount = React.Children.toArray(firstRow.props.children).length;
                }
              }
            }
          }

          const isPermissions =
            lastHeadingRef.current && lastHeadingRef.current.toLowerCase().includes('permission');

          const columnClass =
            columnCount === 2
              ? styles.table2Cols
              : columnCount === 3
              ? styles.table3Cols
              : columnCount === 4
              ? styles.table4Cols
              : columnCount === 5
              ? styles.table5Cols
              : '';

          const finalClassName = `${columnClass} ${isPermissions ? styles.permissions : ''}`.trim();

          return (
            <table {...props} className={finalClassName}>
              {children}
            </table>
          );
        },
      }}
    />
  );
};

export default DocsMarkdown;

/* Prism Styles 
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { cb } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { duotoneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { duotoneEarth } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { duotoneForest } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { duotoneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { duotoneSea } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { duotoneSpace } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ghcolors } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { hopscotch } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { funky } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { twilight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { twilight } from 'react-syntax-highlighter/dist/esm/styles/prism';
*/
