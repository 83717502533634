import {
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { FC, useContext, useEffect, useState } from "react";
import { SettingsServiceContext } from "../../../Services/API/SettingsService";
import ISettings from "../../../Models/API/ISettings";
import { DialogServiceContext } from "../../../Services/Dialogs/DialogService";
import moment from "moment";
import { trackPageView } from "../../../Services/AppInsights";

const PromptV2Settings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);

  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [currentKey, setCurrentKey] = useState<string>("search");

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog(
      "Settings saved",
      "All settings have been saved."
    );
    setSaving(false);
  };

  return (
    (settings && (
      <>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <Pivot
              aria-label="Chat App Options"
              selectedKey={currentKey}
              onLinkClick={(item) => setCurrentKey(item?.props.itemKey!)}
            >
              <PivotItem
                itemKey="toolprompt"
                headerText="Tools"
                itemIcon="LightningBolt"
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.tool_system_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        tool_system_prompt: event.currentTarget.value,
                      })
                    }
                    label="System Prompt"
                    multiline
                    rows={35}
                  />
                  <Text variant={"xSmall"} block>
                    The system prompt used when detecting the best tool to use.
                    <br />
                    <br />
                    <strong>
                      {"{"}personality{"}"}
                    </strong>{" "}
                    - The system prompt of the chat application.
                    <br />
                    <strong>
                      {"{"}attachments{"}"}
                    </strong>{" "}
                    - The attachments set by the user.
                    <br />
                    <strong>
                      {"{"}current_date{"}"}
                    </strong>{" "}
                    - The current date and hour.
                    <br />
                    <strong>
                      {"{"}name{"}"}
                    </strong>{" "}
                    - The user's full name
                    <br />
                    <strong>
                      {"{"}office_location{"}"}
                    </strong>{" "}
                    - The user's office location
                    <br />
                    <strong>
                      {"{"}job_title{"}"}
                    </strong>{" "}
                    - The user's job title
                    <br />
                    <strong>
                      {"{"}country{"}"}
                    </strong>{" "}
                    - The user's country
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey="chitchatprompt"
                headerText="Chit Chat"
                itemIcon="Chat"
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.chitchat_system_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        chitchat_system_prompt: event.currentTarget.value,
                      })
                    }
                    label="System Prompt"
                    multiline
                    rows={35}
                  />
                  <Text variant={"xSmall"} block>
                    The system prompt used when chit chat is detected.
                    <br />
                    <br />
                    <strong>
                      {"{"}personality{"}"}
                    </strong>{" "}
                    - The system prompt of the chat application.
                    <br />
                    <strong>
                      {"{"}num_words{"}"}
                    </strong>{" "}
                    - The number of maximum words to generate in the completion.
                    <br />
                    <strong>
                      {"{"}current_date{"}"}
                    </strong>{" "}
                    - The current date and hour.
                    <br />
                    <strong>
                      {"{"}name{"}"}
                    </strong>{" "}
                    - The user's full name
                    <br />
                    <strong>
                      {"{"}office_location{"}"}
                    </strong>{" "}
                    - The user's office location
                    <br />
                    <strong>
                      {"{"}job_title{"}"}
                    </strong>{" "}
                    - The user's job title
                    <br />
                    <strong>
                      {"{"}country{"}"}
                    </strong>{" "}
                    - The user's country
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey="mainprompt"
                headerText="Grounding"
                itemIcon="SearchAndApps"
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.main_system_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        main_system_prompt: event.currentTarget.value,
                      })
                    }
                    label="System Prompt"
                    multiline
                    rows={35}
                  />
                  <Text variant={"xSmall"} block>
                    The system prompt used when grounding a chat application on data.
                    <br />
                    <br />
                    <strong>
                      {"{"}personality{"}"}
                    </strong>{" "}
                    - The system prompt of the chat application.
                    <br />
                    <strong>
                      {"{"}num_words{"}"}
                    </strong>{" "}
                    - The number of maximum words to generate in the completion.
                    <br />
                    <strong>
                      {"{"}current_date{"}"}
                    </strong>{" "}
                    - The current date and hour.
                    <br />
                    <strong>
                      {"{"}name{"}"}
                    </strong>{" "}
                    - The user's full name
                    <br />
                    <strong>
                      {"{"}office_location{"}"}
                    </strong>{" "}
                    - The user's office location
                    <br />
                    <strong>
                      {"{"}job_title{"}"}
                    </strong>{" "}
                    - The user's job title
                    <br />
                    <strong>
                      {"{"}country{"}"}
                    </strong>{" "}
                    - The user's country
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey="summarizedocument"
                headerText="Summarize Document"
                itemIcon="TextDocument"
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.summarizedocument_system_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        summarizedocument_system_prompt:
                          event.currentTarget.value,
                      })
                    }
                    label="System Prompt"
                    multiline
                    rows={35}
                  />
                  <Text variant={"xSmall"} block>
                    The system prompt used requesting the summary of a document.
                    <br />
                    <br />
                    <strong>
                      {"{"}intent{"}"}
                    </strong>{" "}
                    - The intent of the user to summarize the document.
                    <br />
                    <strong>
                      {"{"}content{"}"}
                    </strong>{" "}
                    - The content of the document to summarize.
                  </Text>
                </Stack.Item>
              </PivotItem>

              <PivotItem
                itemKey="finalprompt"
                headerText="Final Prompt"
                itemIcon="TextDocumentShared"
              >
                <Stack.Item style={{ maxWidth: 800, width: 800 }}>
                  <TextField
                    value={settings.final_system_prompt}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        final_system_prompt: event.currentTarget.value,
                      })
                    }
                    label="System Prompt"
                    multiline
                    rows={35}
                  />
                  <Text variant={"xSmall"} block>
                    The system prompt used when summarizing the result of previous tools.
                    <br />
                    <br />
                    <strong>
                      {"{"}personality{"}"}
                    </strong>{" "}
                    - The system prompt of the chat application.
                    <br />
                    <strong>
                      {"{"}num_words{"}"}
                    </strong>{" "}
                    - The number of maximum words to generate in the completion.
                    <br />
                    <strong>
                      {"{"}current_date{"}"}
                    </strong>{" "}
                    - The current date and hour.
                    <br />
                    <strong>
                      {"{"}name{"}"}
                    </strong>{" "}
                    - The user's full name
                    <br />
                    <strong>
                      {"{"}office_location{"}"}
                    </strong>{" "}
                    - The user's office location
                    <br />
                    <strong>
                      {"{"}job_title{"}"}
                    </strong>{" "}
                    - The user's job title
                    <br />
                    <strong>
                      {"{"}country{"}"}
                    </strong>{" "}
                    - The user's country
                  </Text>
                </Stack.Item>
              </PivotItem>
            </Pivot>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? "Saving..." : "Save"}
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default PromptV2Settings;
