import { FC, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import IChatApp from '../../../../../../../Models/API/IChatApp';
import { chatAppServiceContext } from '../../../../../../../Services/API/ChatAppService';
import IChatAppHierarchy from '../../../../../../../Models/API/IChatAppHierarchy';
import HierarchyNode from './HierarchyNode';

export const transformHierarchyData = (data: IChatAppHierarchy[]): any => {
  const transformNode = (node: IChatAppHierarchy): any => {
    // Create the base node structure
    const chartNode: any = {
      name: node.DisplayName,
      fill: '#0099BC',
      children: [],
    };

    // Add SearchFunctions as children nodes
    for (const searchFunction of node.SearchFunctions) {
      chartNode.children.push({
        name: searchFunction.DisplayName,
        fill: '#0099BC',
        children: [],
      });
    }

    // Add Functions as children nodes
    for (const func of node.Functions) {
      chartNode.children.push({
        name: func.DisplayName,
        fill: '#0099BC',
        children: [],
      });
    }

    // Recursively add ChildApps as children nodes
    for (const childApp of node.ChildApps) {
      chartNode.children.push(transformNode(childApp));
    }

    return chartNode;
  };

  return transformNode(data[0]);
};

export interface IChatAppHierarchyProps {}

const ChatAppHierarchy: FC = () => {
  const chatAppsService = useContext(chatAppServiceContext);
  const { chatApp } = useOutletContext<{ chatApp: IChatApp }>();

  const [hierarchyData, setHierarchyData] = useState<IChatAppHierarchy | null>(null);

  useEffect(() => {
    const execute = async () => {
      let chatAppChats = await chatAppsService!.GetChatAppHierarchy(chatApp.Id);
      if (chatAppChats && chatAppChats.length > 0) {
        setHierarchyData(chatAppChats[0]);
      }
    };
    execute();
  }, [chatApp.Id, chatAppsService]);

  return (
    <div>
      {!hierarchyData && <div>Loading...</div>}
      {hierarchyData && <HierarchyNode node={hierarchyData} />}
    </div>
  );
};

export default ChatAppHierarchy;
