import { FC } from "react";
import { ISource } from "../../Models/API/IConversation";
import "./AssistantMessageSources.scss";
import { Icon, Stack, StackItem } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import ConfigurationService from "../../Services/ConfigurationService";

export interface IAssistantMessageSourcesProps {
  sources: ISource[];
}

const fileName = (fileName: string) => {
  const nameLength = 50;
  return fileName.length > nameLength
    ? `${fileName.substring(0, nameLength)}...`
    : fileName;
};

const attachmentLink = (source: ISource) => {
  if (source.type === "DOCUMENT") {
    return `${ConfigurationService.Default.Configuration.API?.BaseUri}/documents/${source.document_id}/open`;
  } else if (source.type === "USER_DOCUMENT") {
    return `${ConfigurationService.Default.Configuration.API?.BaseUri}/users/documents/${source.document_id}/open`;
  } else if (source.type === "WEBPAGE") {
    return `${source.url}`;
  }
};

const AssistantMessageSources: FC<IAssistantMessageSourcesProps> = ({
  sources,
}) => {
  return (
    <Stack wrap horizontalAlign="start" horizontal tokens={{ childrenGap: 10 }}>
      {sources &&
        sources
          .filter(
            (source) =>
              (source.document_id || source.url) && source.name && source.type
          )
          .map((source, index) => {
            return (
              <StackItem key={`assistant_source_${index}`}>
                <a
                  className="msg-attachment"
                  title={source.name}
                  href={attachmentLink(source)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="icon">
                    <Icon
                      {...getFileTypeIconProps({
                        extension:
                          source.type === "WEBPAGE"
                            ? "url"
                            : source.name.split(".")[
                                source.name.split(".").length - 1
                              ],
                        size: 20,
                        imageFileType: "svg",
                      })}
                    />
                  </span>
                  <span className="name">{fileName(source.name)}</span>
                </a>
              </StackItem>
            );
          })}
    </Stack>
  );
};

export default AssistantMessageSources;
