import { FC, useEffect, useRef, useState } from 'react';
import AITool from './AITool/AITool';
import styles from './AIToolsCarousel.module.scss';
import { ActionButton } from '@fluentui/react';
import IAIToolApp from '../../Models/API/IAIToolApp';

const ITEM_SIZE = 292;
const ITEM_GAP = 20;

export interface AIToolsCarouselProps {
  tools: IAIToolApp[];
}

const AIToolsCarousel: FC<AIToolsCarouselProps> = ({ tools }) => {
  const carousel = useRef<HTMLDivElement>(null);
  const slider = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    updatePaging();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  function onResize(this: Window, ev: UIEvent): any {
    goToPage(0);
    updatePaging();
  }

  const updatePaging = () => {
    var width = slider.current!.clientWidth;
    const itemsPerPage = Math.floor(width / ITEM_SIZE);
    const pages = Math.ceil(tools.length / itemsPerPage);
    setPages(pages);
  };

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    var width = slider.current!.clientWidth;
    const itemsPerPage = Math.floor(width / (ITEM_SIZE + ITEM_GAP));
    carousel.current!.scrollTo({
      left: pageNumber * (ITEM_SIZE + ITEM_GAP) * itemsPerPage,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div ref={carousel} className={styles.carousel}>
        <div className={styles.slider}>
          <div ref={slider} className={styles.items}>
            {tools.map(tool => (
              <AITool key={tool.Id} tool={tool} />
            ))}
          </div>
        </div>
      </div>
      {pages > 1 && (
        <div className={styles.paging}>
          {Array.from({ length: pages }, (_, i) => (
            <ActionButton
              className={i === currentPage ? styles.active : ''}
              onClick={_ => goToPage(i)}
              key={i}
              iconProps={{ iconName: 'FullCircleMask' }}
            ></ActionButton>
          ))}
        </div>
      )}
    </>
  );
};

export default AIToolsCarousel;
