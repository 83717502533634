import { FC, useContext, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import IPermission from '../../../../../../Models/API/IPermission';
import PermissionManagement from '../../../../../../Components/PermissionManagement/PermissionManagement';
import { trackPageView } from '../../../../../../Services/AppInsights';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import { aiToolAppServiceContext } from '../../../../../../Services/API/AiToolAppService';
import IAIToolApp from '../../../../../../Models/API/IAIToolApp';

const AiToolAppPermissions: FC = () => {
  const { aiToolApp } = useOutletContext<{
    aiToolApp: IAIToolApp;
  }>();
  const aiToolsAppsService = useContext(aiToolAppServiceContext);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${aiToolApp.DisplayName} Permissions`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _addPermission = async (objectId: string, permissionType: string) => {
    return await aiToolsAppsService!.CreatePermission(
      aiToolApp.Id,
      objectId,
      permissionType as any
    );
  };

  const _deletePermission = async (objectId: string) => {
    await aiToolsAppsService!.DeletePermission(aiToolApp.Id, objectId);
  };

  const _reloadPermissions = async () => {
    var perms = await aiToolsAppsService!.GetPermissions(aiToolApp.Id);
    return perms as IPermission[];
  };

  const _checkPermissions = async (objectId: string) => {
    var perms = await aiToolsAppsService!.CheckUserPermissions(objectId, aiToolApp.Id);
    return perms ?? [];
  };

  return (
    <PermissionManagement
      resourceType={'AutomateApp'}
      resourceId={aiToolApp.Id}
      permissions={{
        Read: {
          DisplayName: 'Read',
          Description: 'Users can interact with the application',
        },
        Write: {
          DisplayName: 'Write',
          Description: 'Users can edit the application settings and manage parameters',
        },
        FullControl: {
          DisplayName: 'Full Control',
          Description: 'Users can change the permissions of the application',
        },
        Owner: {
          DisplayName: 'Owner',
          Description: 'Users can add other owners and delete the application',
        },
      }}
      isOwner={aiToolApp.PermissionType === 'Owner'}
      addPermission={_addPermission}
      deletePermission={_deletePermission}
      reloadPermissions={_reloadPermissions}
      checkPermissions={_checkPermissions}
    />
  );
};

export default AiToolAppPermissions;
