import { Image, PrimaryButton, Stack, Text } from '@fluentui/react';
import { FC, useEffect } from 'react';
import './AIAppsCatalog.scss';
import jti_fade from '../../Assets/Images/aiappscatalog/jti_fade.png';
import mesh from '../../Assets/Images/aiappscatalog/mesh.png';
import { useNavigate } from 'react-router-dom';
import { trackPageView } from '../../Services/AppInsights';
import ConfigurationService from '../../Services/ConfigurationService';

const AIAppsCatalog: FC = () => {
  const navigate = useNavigate();

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - AI Service Catalog`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      className='ai-apps-home'
      verticalAlign='center'
      grow
      horizontal
      style={{ minHeight: 0, overflowY: 'auto' }}
    >
      <Stack style={{ maxWidth: '50%' }}>
        <Image className='image' src={jti_fade}></Image>
        <Text block variant='large' className='title'>
          Get started with <strong>JTI AI Service Catalog</strong>
        </Text>
        <Text block className='description'>
          The JTI AI Service Catalog provides JTI users with a platform to explore, experiment with,
          and deploy a range of pre-built AI services tailored for the organization.
        </Text>
        <Text block className='description'>
          This catalog includes tools like predictive analytics, natural language processing, and
          computer vision models that can be tested and customized in a secure environment.
        </Text>
        <Text block className='description'>
          Designed for easy integration with JTI’s existing systems and workflows, users can deploy
          these AI solutions to enhance operations, improve decision-making, and drive innovation
          across departments. The service simplifies the process of adopting AI, empowering JTI
          teams to harness advanced technologies seamlessly.
        </Text>
        <PrimaryButton onClick={_ => navigate({ pathname: 'apps' })} style={{ maxWidth: 200 }}>
          Discover the catalog
        </PrimaryButton>
      </Stack>
      <Stack style={{ width: '50%' }} verticalFill verticalAlign='end'>
        <Image className='mesh' src={mesh}></Image>
      </Stack>
    </Stack>
  );
};

export default AIAppsCatalog;
